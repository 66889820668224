import 'react-app-polyfill/ie9';
import 'weakmap-polyfill';
import 'intl-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';
import {definitions} from './config';

document.title = definitions.title;

ReactDOM.render(<App />, document.getElementById('root'));
